import { TYPE_SERVER, TYPE_CLOUD } from '../constants/app'
import i18n from '../plugins/i18n'

const getLocalizedContractProductType = contract => {
  if (contract && contract.product) {
    if (contract.product.type === TYPE_SERVER) {
      return i18n.t('contractCard.inServer')
    }

    if (contract.product.type === TYPE_CLOUD) {
      return i18n.t('contractCard.onCloud')
    }
  }

  return i18n.t('contractCard.unknownType')
}

export default getLocalizedContractProductType
