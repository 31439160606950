<template>
  <v-simple-table>
    <thead>
      <tr>
        <th
          v-t="'payment.state'"
          class="text-164
          "
        />
        <th
          v-t="'payment.contractId'"
          class="text-left"
        />
        <th
          v-t="'payment.product'"
          class="text-left"
        />
        <th
          v-t="'payment.paymentMethod'"
          class="text-left"
        />
        <th
          v-t="'payment.nextPayment'"
          class="text-left"
        />
        <th
          v-t="'payment.period'"
          class="text-left"
        />
        <th
          v-t="'payment.amount'"
          class="text-left"
        />
        <th
          v-t="'actions'"
          class="text-end"
        />
      </tr>
    </thead>
    <tbody data-cy="recurring-payment-table">
      <tr v-if="payments.length === 0">
        <td
          v-t="'payment.youDoNotHaveAnyPaymentYet'"
          class="text-center text--secondary"
          colspan="8"
        />
      </tr>
      <tr
        v-for="payment in payments"
        v-else
        :key="payment.id"
      >
        <td>
          <v-chip
            v-t="
              `payment.${camelize(
                payment.enabled
                  ? recurringPaymentsEnabled
                  : recurringPaymentsDisabled
              )}`
            "
            :color="payment.enabled ? 'success' : 'error'"
            text-color="white"
            data-cy="recurring-state"
          />
        </td>
        <td>{{ payment.contract.id }}</td>
        <td>
          <b class="mr-1">{{ payment.contract.product.name }}</b>
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="mr-1"
            v-html="getLocalizedContractProductType(payment.contract)"
          />
        </td>
        <td>
          {{ payment.methodName }}
        </td>
        <template v-if="payment.enabled">
          <td>
            {{ payment.nextPayment | formatDate }}
          </td>
          <td>
            {{ payment | recurringPeriodToString }}
          </td>
          <td class="text-right">
            {{ payment.amount }}
          </td>
          <td class="text-no-wrap text-right pr-3">
            <v-tooltip
              v-if="!editingLoading[payment.id]"
              bottom
              max-width="600"
            >
              <template #activator="{ on }">
                <a
                  class="edit-payment-link"
                  data-cy="payment-edit"
                  v-on="on"
                  @click="redirectToEdit(payment.id)"
                >
                  <v-icon>
                    {{ editIcon }}
                  </v-icon>
                </a>
              </template>
              <material-recurring-payments-edit-warning-info />
            </v-tooltip>
            <v-progress-circular
              v-else
              size="20"
              width="3"
              indeterminate
            />
            <material-recurring-payments-cancel-modal-window :payment-id="payment.id" />
          </td>
        </template>
        <template v-else>
          <td
            colspan="4"
            data-cy="activating-recurring-payment-message"
          >
            <core-element-info-message
              class="my-4"
              :message="
                $t(
                  'payment.activatingRecurringPaymentsIsPossibleViaPaymentGateOrderCheckout'
                )
              "
            />
          </td>
        </template>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  CHECKED_CIRCLE_ICON,
  CLOSED_CIRCLE_ICON,
  DOWNLOAD_DOCUMENT_ICON,
  INFO_ICON,
  MONTH_PERIOD,
  RECURRING_PAYMENTS_DISABLED,
  RECURRING_PAYMENTS_ENABLED,
  INDENT,
  EDIT_ICON
} from '../../../constants/app'
import getLocalizedContractProductType from '../../../utils/getLocalizedContractProductType'

export default {
  data () {
    return {
      editIcon: EDIT_ICON,
      placeholder: INDENT,
      downloadIcon: DOWNLOAD_DOCUMENT_ICON,
      monthPeriod: MONTH_PERIOD,
      recurringPaymentsEnabled: RECURRING_PAYMENTS_ENABLED,
      recurringPaymentsDisabled: RECURRING_PAYMENTS_DISABLED,
      infoIcon: INFO_ICON,
      checkedCircle: CHECKED_CIRCLE_ICON,
      closedCircle: CLOSED_CIRCLE_ICON
    }
  },
  computed: {
    ...mapGetters('recurringPayments', {
      paymentsGetter: 'client'
    }),
    ...mapState('recurringPayments', ['editingLoading']),
    payments () {
      return this.paymentsGetter(this.$route.params.clientId)
    }
  },
  methods: {
    ...mapActions('recurringPayments', ['redirectToStripeEditing']),
    getLocalizedContractProductType,
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    redirectToEdit: async function (paymentId) {
      this.redirectToStripeEditing({ clientId: this.$route.params.clientId, paymentId, returnPath: this.$route.path })
    }
  }
}
</script>

<style lang="scss">
  .edit-payment-link {
    text-decoration: none;
  }

  .recurring-payment-table-tooltip {
    background-color: var(--v-grey-darken2);
    opacity: 1;
  }
</style>
