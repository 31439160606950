var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.state'),expression:"'payment.state'"}],staticClass:"text-164\n        "}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.contractId'),expression:"'payment.contractId'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.product'),expression:"'payment.product'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.paymentMethod'),expression:"'payment.paymentMethod'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.nextPayment'),expression:"'payment.nextPayment'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.period'),expression:"'payment.period'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('payment.amount'),expression:"'payment.amount'"}],staticClass:"text-left"}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('actions'),expression:"'actions'"}],staticClass:"text-end"})])]),_c('tbody',[(_vm.payments.length === 0)?_c('tr',[_c('td',{directives:[{name:"t",rawName:"v-t",value:('payment.youDoNotHaveAnyPaymentYet'),expression:"'payment.youDoNotHaveAnyPaymentYet'"}],staticClass:"text-center text--secondary",attrs:{"colspan":"8"}})]):_vm._l((_vm.payments),function(payment){return _c('tr',{key:payment.id},[_c('td',[_c('v-chip',{directives:[{name:"t",rawName:"v-t",value:(
            ("payment." + (_vm.camelize(
              payment.enabled
                ? _vm.recurringPaymentsEnabled
                : _vm.recurringPaymentsDisabled
            )))
          ),expression:"\n            `payment.${camelize(\n              payment.enabled\n                ? recurringPaymentsEnabled\n                : recurringPaymentsDisabled\n            )}`\n          "}],attrs:{"color":payment.enabled ? 'success' : 'error',"text-color":"white"}})],1),_c('td',[_vm._v(_vm._s(payment.contract.id))]),_c('td',[_c('b',{staticClass:"mr-1"},[_vm._v(_vm._s(payment.contract.product.name))]),_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.getLocalizedContractProductType(payment.contract))}})]),_c('td',[_vm._v(" "+_vm._s(payment.methodName)+" ")]),(payment.enabled)?[_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(payment.nextPayment))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("recurringPeriodToString")(payment))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(payment.amount)+" ")]),_c('td',{staticClass:"text-no-wrap text-right pr-3"},[(!_vm.editingLoading[payment.id])?_c('v-tooltip',{attrs:{"bottom":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',_vm._g({staticClass:"edit-payment-link",on:{"click":function($event){return _vm.redirectToEdit(payment.id)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])],1)]}}],null,true)},[_c('material-recurring-payments-edit-warning-info')],1):_c('v-progress-circular',{attrs:{"size":"20","width":"3","indeterminate":""}}),_c('material-recurring-payments-cancel-modal-window',{attrs:{"payment-id":payment.id}})],1)]:[_c('td',{attrs:{"colspan":"4"}},[_c('core-element-info-message',{staticClass:"my-4",attrs:{"message":_vm.$t(
                'payment.activatingRecurringPaymentsIsPossibleViaPaymentGateOrderCheckout'
              )}})],1)]],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }